/// <reference path="../../../_all.ts"/>

module Shared {

    interface IBrooksonBankLinkTransactionsBindings {
        getData: Array<any>;
        businessOnly: Array<any>;
    }

    export class BrooksonPaymentCreatePayment implements ng.IComponentOptions {
        public controller: Function = BrooksonPaymentCreatePaymentController;
        public templateUrl: string = 'src/app/shared/views/bank/brookson.bankLink.transactions.html';
    }

    class BrooksonPaymentCreatePaymentController implements IBrooksonBankLinkTransactionsBindings {
        static $inject = ["$window", "$location", '$state'];

        public getData;
        public businessOnly;

        vatRateConfig: Array<VatRateConfig>;

        constructor(
            private window,
            private $location: ng.ILocationService,
            private $state: ng.ui.IStateService,
        ) { }

        $onInit = () => {
        }

        invokeBackButton = (): void => {
            this.window.history.back();
        }
    }
}

angular
    .module('app.shared')
    .component('brooksonPaymentCreatePayment', new Shared.BrooksonPaymentCreatePayment());